import React from 'react'

import CorporateWellness from '../components/CorporateWellness';
import Contact from '../components/Contact';
import Footer from '../components/Footer';
import Aos from 'aos';
import 'aos/dist/aos.css';

const Corporate = () => {
    Aos.init({
        duration: 1800,
        offset: 100,
      });
    return (

        <div className='overflow-hidden'>
            <div className='pt-9 pb-3 bg-[#6aa299]'>
                <CorporateWellness />
            </div>
            <div>
                <Contact getCourseName={'Corporate Wellness Session'}/>
                <Footer />
            </div>
        </div>
    )
}

export default Corporate
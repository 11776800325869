import React, {useState, useEffect} from 'react';

// import aos
import Aos from 'aos';
import 'aos/dist/aos.css';

// import components
import About from '../components/About';
import Video from '../components/Video';
import Pricing from '../components/Pricing';
import Contact from '../components/Contact';
import Footer from '../components/Footer';
import Testimonial from '../components/Testimonial';
import Courses from '../components/Courses';
import Hypnotherapy from '../components/Hypnotherapy';
import LandingMobile from '../components/Parallax/LandingMobile';
import LandingParallax from '../components/Parallax/LandingParallax';
import PricingMobile from '../components/Pricing/PricingMobile';

const Home = () => {
  Aos.init({
    duration: 1800,
    offset: 100,
  });
  const [width, setWidth] =  useState(window.innerWidth);
  const [isMobile, setIsMobile] = useState(false);
  const [courseName, setCourseName] = useState('')

  useEffect(() => {
    const handleWindowResize = () => setWidth(window.innerWidth)
    window.addEventListener("resize", handleWindowResize);
    
    if (width < 700){
      setIsMobile(true);
    }
    else {
      setIsMobile(false);
    }

  }, [width])

  return (
    <div className='overflow-hidden'>
      {isMobile ?  <LandingMobile /> : <LandingParallax />}
      <About />
      <Hypnotherapy />
      <Courses />
      <Testimonial /> 
      <Video />
      {isMobile ? <PricingMobile setCourseName={setCourseName}/> : <Pricing setCourseName={setCourseName}/> } 
      <Contact getCourseName={courseName}/>
      <Footer />
    </div>
  );
};

export default Home;
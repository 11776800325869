import React, { useState, useEffect } from 'react';

// import modal video
import ModalVideo from 'react-modal-video';

import { Link } from 'react-scroll';
// import icons
import { BsPlayCircleFill } from 'react-icons/bs';

const Video = () => {
  const [isOpen, setOpen] = useState(false);
  const [width, setWidth] =  useState(window.innerWidth);
  const [isMobile, setIsMobile] = useState(false);
  

  useEffect(() => {
    const handleWindowResize = () => setWidth(window.innerWidth)
    window.addEventListener("resize", handleWindowResize);
    
    if (width < 700){
      setIsMobile(true);
    }
    else {
      setIsMobile(false);
    }

  }, [width])

  return (
    <section className='bg-section min-h-[428px] lg:pt-[100px]  pt-[38px] pb-[38px] lg:pb-[100px] mt-[20px] lg:mt-[130px]' id='video'>
      <div className='container mx-auto'>
        {/* <div
          className='bg-video bg-cover bg-center bg-no-repeat h-[310px] lg:h-[622px] w-full flex justify-center items-center rounded-md -mt-[150px] mb-[28px] lg:mb-20'
          data-aos='fade-up'
          data-aos-duration={`${isMobile ? '500' : '1500'}`}
          // data-aos-offset={`${isMobile ? '200' : '500'}`}
        >
          <div
            onClick={() => setOpen(!isOpen)}
            className='text-6xl lg:text-[120px] text-[#6aa299] cursor-pointer hover:scale-110 transition'
          >
            <BsPlayCircleFill />
          </div>
        </div> */}
        <div className='flex flex-col-reverse lg:flex-row lg:gap-x-8'>
          <div className='flex-1 space-y-4 pb-5 items-center text-aligment-center lg:text-start lg:items-start' data-aos='fade-right'>
            <div className='w-9 h-[2px] bg-[#6aa299] mb-2 lg:w-[70px] rounded-full'></div>
            <h3 className='h3 mb-3 lg:mb-0'>
               <em>Start Hypnotherapy</em>
            </h3> 
             <button className='btn btn-sm btn-green-500'>
              <Link to='contact' spy={true} smooth={true} offset={50} duration={1000}>Contact me today</Link>
            </button>
          </div>
          <div className='flex-1 text-center md:text-start' data-aos='fade-left'>
            <p className='max-w-[360px] mb-[18px] lg:mb-[38px]'>
            We all have a natural fear response, fight, flight, freeze, a primitive emotion for our survival.  These days our emotional response to fear is the same, although we are not often hiding from polar bears.  We all feel fearful or anxious from time to time, but for some, 
            it may have become a more regular occurrence and can start to impact everyday life.
            </p>
          
          </div>
        </div>
      </div>


      <ModalVideo
        channel='youtube'
        autoplay
        isOpen={isOpen}
        videoId='fMZ0Vvn5sxM'
        onClose={() => setOpen(false)}
      />
    </section>
  );
};

export default Video;
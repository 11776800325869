import React from 'react'
import Header from '../Header';
import { Parallax } from 'react-parallax'
import { Link } from 'react-scroll';
import Image from '../../assets/img/background/mountain-2.jpg';


const LandingParallax = () => {
  return (
    <Parallax
    bgImage={Image}
    strength={400}
    renderLayer={percentage => (
      <section className='pt-9 lg:mb-[380px] bg-[#a5c9c3a1]' id='home'>
        <div className='container mx-auto'>
          < Header /> 
          <div className='grid lg:h-screen place-items-center pt-32 lg:pb-32 pb-12'>
            <div className='lg:mt-24 self-auto'>
              <h2
                className="h2 mb-3 text-center lg:mb-[22px] lg:text-5xl sm:text-xl"
                data-aos='fade-up'
                data-aos-delay='2000'
                data-aos-duration='4000'
              >
                A Tranquil Mind <br /> <span className='lg:text-[#758e8cbf] text-[#335e5bdb]'>A Tranquil Life </span>
              </h2>
              <p
                className="text-[#000000] mb-6 text-center lg:mb-12 max-w-[480px] lg:text-lg font-['Courgette']"
                data-aos='fade-up'
                data-aos-delay='2200'
                data-aos-duration='5000'
              >
              Hi, I’m Shona and I am a Solution Focused Hypnotherapist and Psychotherapist based in Farnham, Surrey
              </p>
              <div
                className='space-x-4 text-center sm:pt-14'
                data-aos='fade-up'
                data-aos-delay='2400'
                data-aos-duration='6000'
              >
                <button className='btn btn-sm lg:btn-lg bg-[#6aa299] text-white lg:mr-2 hover:bg-[#758e8cbf] '>
                  <Link to='contact' spy={true} smooth={true} offset={50} duration={1000}>
                    Contact Me
                  </Link>
                </button>

              </div>
            </div>
        
          </div>
        </div>
      </section>
    ) }/>
  )
}

export default LandingParallax
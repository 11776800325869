import React from 'react';
import { Routes, Route } from 'react-router-dom';
import Home from './pages/Home';
import Privacy from './pages/Privacy';
import Corporate from './pages/Corporate';

const App = () => {

  return (
    <Routes>
      <Route path="/" element={<Home/>} />
      <Route path="/privacy" element={<Privacy/>} />
      <Route path="/corporate" element={<Corporate/>} />
    </Routes>
  );
};

export default App;
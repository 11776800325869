import React from 'react'
import Header from '../components/Header'
const Privacy = () => {
  return (

    <div className='overflow-hidden'>
      <section className='pt-9 pb-3 bg-[#6aa299]' id='home'>
        <div className='container mx-auto'>
          <Header />
          <div
          className='flex flex-col lg:flex-row bg-white shadow-xl rounded-lg mt-36 mb-32 bg-no-repeat bg-cover min-h-[800px]'
        >
            <div
            className='flex-1 flex flex-col px-8 text-center'
          >
            <h2 className='h2 text-[20px] font-medium'>
              Privacy Policy
            </h2>
            <p>
            Shona Hypnotherapy will be what’s known as the ‘Controller’ of the personal data you provide to us. We only collect data relevant to your therapy which will be stored on a password protected and encrypted drive or cloud-based server.  The information that we collect includes but is not limited to name, address, email, phone number and circumstances around which you require therapy.  We have updated our privacy policy to meet the high standards o the new European Data Protection Law, known as the General Data Protection Regulation (GDPR).

            </p>
            <h3 className='h3 text-[15px] font-medium mt-1 mb-1'>
              Why we need your data?
            </h3>
            <p>
            We require this data to create a tailored therapy session to fit your needs and outcomes. Data regarding contact information and addresses are taken to allow us to reach you in the event of a change in circumstances or for us to pass on to the emergency services should you inform us outside of a session that you are currently a risk to the safety of yourself or others. We will not collect any personal data from you we do not need in order to provide and oversee this service to you. 
            </p>
            <h3 className='h3 text-[15px] font-medium mt-1 mb-1'>
            What we do with your data?
            </h3>
            <p>
            All the data processed will remain confidential between yourself and the therapist, however, the therapist may talk about your case with all your personal information redacted in a supervision capacity with their supervisor/supervision team (other therapists) in order to provide you with the best service possible. We will not pass your data onto any other person or organisation without your explicit prior consent.  Handwritten client notes are securely stored within the practice. They are destroyed by shredding or incineration. 
            </p>
            <h3 className='h3 text-[15px] font-medium mt-1 mb-1'>
            How long we keep your data?
            </h3>
            <p>
            We are required by our governing association membership to keep records of our clients for adults for 6 years from the completion of their therapy. For clients under the age of 18, we are required by GDPR law to keep records until they turn 21 or for 6 years from the end of their therapy (whichever is greater).
This includes both information about why you are seeking therapy and the notes taken at your session. Paper documents will be scanned and stored electronically and then will be shredded upon completion of your therapy.
              </p>
              <h3 className='h3 text-[15px] font-medium mt-1 mb-1'>
              What are your rights?
            </h3>
            <p>
            If at any point you believe the information we process on you is incorrect you can request to see this information and have it corrected or deleted. If you wish to raise a complaint on how we have handled your personal data, you can contact us to have the matter investigated. 
            </p>
            <p>
            If you are not satisfied with our response or believe we are processing your personal data not in accordance with the law, you can complain to the Information Commissioner’s Office. <br /> 
<a href='https://ico.org.uk/'>https://ico.org.uk/</a>
              </p>
            </div>
          </div>
        </div>
      </section>
    </div>
  )
}

export default Privacy
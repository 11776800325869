import React, {useState, useEffect} from 'react';
import { Link } from 'react-scroll';
import {navigation} from '../data'
import { useLocation } from 'react-router-dom';

const Nav = () => {

  const [header, setHeader] = useState(false);
  const location = useLocation();
  const [width, setWidth] = useState(window.innerWidth);
  const [transparentSize, setTransparentSize] = useState(200);
  const [pathMain, setPathMain] = useState(false);
  const breakpoint = 1100;

  useEffect(() => {
    const handleWindowResize = () => setWidth(window.innerWidth)
    window.addEventListener("resize", handleWindowResize);

    if (width < breakpoint) {
      setTransparentSize(200);
    }
    else {
      setTransparentSize(1000);
    }

  }, [width])

  useEffect(() => {
    window.addEventListener('scroll', () => {
      window.scrollY > transparentSize ? setHeader(true) : setHeader(false);
    });

    if (location.pathname == '/') {
      setPathMain(true);
    }
    else {
      setPathMain(false);
    }
  }, []);


  return (
    <nav>
      <ul className={`${header ? `text-black` : `text-white`} flex flex-col p-4 mt-4 rounded-lg border border-gray-100 md:flex-row md:space-x-8 md:mt-0 md:text-md md:font-medium md:border-0 dark:border-gray-700`}>
        {navigation.map((item, index) => {
          if (!pathMain) {
            return( 
              <li key={index} className='cursor-pointer'>
                <a href='/'>{item.name}</a>
              </li>
              )
          }
          else {
            return( 
            <li key={index} className='hover:text-[#6aa299] cursor-pointer'>
              <Link activeClass='border-b-2 border-indigo-600 text-[#6aa299] transition' to={item.href} href={item.page} spy={true} smooth={true} offset={item.offset} duration={1000}>{item.name}</Link>
            </li>
            )
          }
        })}
           {/* <li className='hover:text-[#6aa299] cursor-pointer'>
              <a href='/corporate'>Corporate</a>
            </li> */}
      </ul>
    </nav>

    );
};

export default Nav;

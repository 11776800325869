// import icons
import React from 'react';
import { BsCheck, BsChevronRight, BsCalendar2Range } from 'react-icons/bs';
import { BiTime } from 'react-icons/bi';



// import images
import CourseImage1 from '../src/assets/img/courses/iconstress.jpg';
import CourseImage2 from '../src/assets/img/courses/iconanxiety.jpg';
import CourseImage3 from '../src/assets/img/courses/iconphobia.jpg';
import CourseImage4 from '../src/assets/img/courses/iconpanic.jpg';
import CourseImage5 from '../src/assets/img/courses/iconselfesteem.jpg';
import CourseImage6 from '../src/assets/img/courses/iconexam.jpg';
import CourseImage7 from '../src/assets/img/courses/relaxation.jpg';
import CourseImage8 from '../src/assets/img/courses/icondisturbedsleep.jpg';


export const navigation = [
  {
    name: 'Home',
    href: 'home',
    offset: -50,
    page: '/'
  },
  {
    name: 'About',
    href: 'about',
    offset: -50,
    page: '/'
  },
  {
    name: 'What I can help with',
    href: 'courses',
    offset: -150,
    page: '/'

  },
  {
    name: 'Pricing',
    href: 'pricing',
    offset: -150,
    page: '/'

  }
];


export const courses = [
  {
    image: CourseImage1,
    title: 'Stress',
    desc: 'A reaction to feeling threatened or under pressure. We all feel stressed at times, but too much can impact our lives. Solution Focused Hypnotherapy can help you regain control of your life, relax and sleep better.',
    link: 'Get started',
    delay: '200',
    subNo: 50,
  },
  {
    image: CourseImage2,
    title: 'Anxiety',
    desc: 'A feeling of unease, such as worry or fear. Solution Focused Hypnotherapy can put you back in control, help you to relax and sleep better.',
    link: 'Get started',
    delay: '300',
    subNo: 42,
  },
  {
    image: CourseImage3,
    title: `Phobia's and Fear`,
    desc: 'They can be uncontrollable and irrational causing anxiety and distress. Solution Focused Hypnotherapy can help your mind to adopt a new way of thinking so you can continue to live life to the full.',
    link: 'Get started',
    delay: '400',
    subNo: 42,
  },
  {
    image: CourseImage4,
    title: 'Panic Attacks',
    desc: 'An anxiety disorder, an unexpected attack of fear or panic. Solution Focused Hypnotherapy can help you regain control.',
    link: 'Get started',
    delay: '500',
    subNo: 42,
  },
  {
    image: CourseImage5,
    title: 'Confidence and low self-esteem',
    desc: 'Many things can affect your confidence and self esteem. Solution Focused Hypnotherapy can break down the negative cycle and suggest a more positive mind set.',
    link: 'Get started',
    delay: '600',
    subNo: 39,
  },
  {
    image: CourseImage6,
    title: 'Exam stress',
    desc: 'A very stressful time for a young person. Solution Focused Hypnotherapy can teach you to cope better with the stress.',
    link: 'Get started',
    delay: '700',
    subNo: 40,
  },
  {
    image: CourseImage7,
    title: 'Relaxation',
    desc: 'We all need time out for ourselves, we often forget to relax and this can lead to overwhelm and stress.  A relaxation session can give you a bit of quality time to yourself, help you to reboot and sleep better.',
    link: 'Get started',
    delay: '700',
    subNo: 39,
  },
  {
    image: CourseImage8,
    title: 'Disturbed Sleep',
    desc: 'Sleep is vital for brain function and balancing emotions.  Lack of sleep can lead to stress, anxiety and overwhelm.  Solution Focused Hypnotherapy can help you maintain healthy sleep patterns and get the real you back.',
    link: 'Get started',
    delay: '700',
    subNo: 38,
  },

];

export const testimonials = [ 
  {
    title: 'Lucy',
    message: 'I can’t thank Shona enough for what she has done for me.  I came to her because I wanted to get over my driving phobia on dual carriageways and motorways which I’d had been avoiding for more than 15 years and having to take ridiculous detours or public transport.  I felt very trapped by my driving restrictions and my feeling of panic when I thought about it and was desperate to try something to help.  Shona made me feel so calm and the environment is so relaxing.  After the sessions addressing the phobia I travelled on the A31 feeling completely relaxed and felt a new lease of life and my freedom back.  It was quite incredible! ',
    location: 'Farnham',
    subNo: 199

  },
  {
    title: 'Sara',
    message: 'I visited Shona because I was feeling rather bereft after my only child left home permanently to move into her own flat, which resulted in disturbed sleep and feelings of sadness and negativity about the future. After just a couple of sessions I began to sleep much better and was much less negative. After just six sessions, which included the ability to listen her immensely soothing hypnotherapy at home, I feel a renewed sense of purpose to embrace the next part of my life. I’d wholly recommend visiting Shona – whatever your issue - to benefit from her sympathetic, positive and accessible approach, the results of which, will help you to face life’s challenges.',
    location: 'Farnham',
    subNo: 200,
  },
  {
    title: 'Fiona',
    message: 'Shona helped me with my anxiety issues through therapeutic and relaxing sessions . I am amazed how she has been able to change my mindset so easily as I was sceptical! I would definitely recommend her to family and friends as she is so empathetic , caring and positive . We laughed together every week because she was so welcoming and made me feel as if I was a friend rather than a client. ',
    location: 'Farnham',
    subNo: 200
  },
  {
    title: 'Debbie',
    message: 'I did not do heights. See through stairs, ladders or step ups then I would get the clammy hands and the sweats. I went along for 4 sessions with Shona and she made me feel very relaxed as I felt a bit anxious, although calm. Since then, I have been able to climb step ladders to get the fruit from the trees, with no hands, something my family still quite cannot believe. It is weird to explain but once I get into a little panic about anything I can still go back to this calm space, knowing that I am not going to get hurt. I would highly recommend seeing Shona for any issues or concerns, as I am proof that after 35 years I am free of my fear.' ,
    location: 'Farnham',
    subNo: 200

  }
 


]

export const pricing = [
  {
    title: 'Initial Consultation',
    price: '£35',
    list: [
      {
        icon: <BiTime />,
        name: '45 minutes',
      },
    ],
    buttonText: 'Book now',
    buttonIcon: <BsChevronRight />,
    delay: '1000',
    mobileDelay: '50'
  },
  {
    title: 'Hypnotherapy Session',
    price: '£70',
    list: [
      {
        icon:  <BiTime />,
        name: '60 minutes',
      },
    ],
    buttonText: 'Book now',
    buttonIcon: <BsChevronRight />,
    delay: '1250',
    mobileDelay: '50'   
  },
  {
    title: 'Relaxation Session',
    price: '£45',
    list: [
      {
        icon:  <BiTime />,
        name: '45 minutes',
      },

    ],
    buttonText: 'Book now',
    buttonIcon: <BsChevronRight />,
    delay: '1650',
    mobileDelay: '0'
  },
  
  {
    title: 'Phobia Session',
    price: '£225',
    list: [
      {
        icon: <BsCalendar2Range />,
        name: '4 Sessions',
      },

    ],
    buttonText: 'Book now',
    buttonIcon: <BsChevronRight />,
    delay: '300',
    mobileDelay: '0'
  },
  {
    title: 'Block Sessions',
    price: '£260',
    list: [
      {
        icon: <BsCalendar2Range />,
        name: '4 sessions',
      },

    ],
    buttonText: 'Book now',
    buttonIcon: <BsChevronRight />,
    delay: '300',
    mobileDelay: '0'
  },
  {
    title: 'Corporate Wellness Session',
    // people: '(Max 6 People)',
    price: 'POA',
    list: [
      {
        icon:  <BsCalendar2Range />,
        name: 'Custom',
      },

    ],
    buttonText: 'Book now',
    buttonIcon: <BsChevronRight />,
    delay: '300',
    mobileDelay: '0'
  },
];

import React, {useState, useEffect } from 'react';

import {BiMenu} from 'react-icons/bi'
import { Link } from 'react-scroll';
import {navigation} from '../data'
import { useLocation } from 'react-router-dom';

const NavMobile = () => {
  const [header, setHeader] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const location = useLocation();
  const [transparentSize, setTransparentSize] = useState(200);
  const [pathMain, setPathMain] = useState(false);

  useEffect(() => {
    window.addEventListener('scroll', () => {
      window.scrollY > transparentSize ? setHeader(true) : setHeader(false);
    });

    if (location.pathname == '/') {
      setPathMain(true);
    }
    else {
      setPathMain(false);
    }
  }, []);

  return (
    <nav>
      <div onClick={() => setIsOpen(!isOpen)} className='cursor-pointer text-4xl text-heading ml-[10px] lg:hidden text-black'>
        <BiMenu />
      </div>

      <ul className={`${isOpen? 'max-h-72 p-8' : 'max-h-0 p-0'} flex flex-col text-[#000000] absolute w-full bg-white top-24 left-0 shadow-primary space-y-6 overflow-hidden
      transition-all`}>
                {navigation.map((item, index) => {
          if (!pathMain) {
            return( 
              <li key={index} className='cursor-pointer'>
                <a href='/'>{item.name}</a>
              </li>
              )
          }
          else {
          return <li key={index}><Link to={item.href} href={item.page} spy={true} smooth={true} offset={item.offset} duration={1000}>{item.name}</Link></li>
        }})}
            {/* <li className='cursor-pointer'>
              <a href='/corporate'>Corporate</a>
            </li> */}
      </ul>
    </nav>

  );
};

export default NavMobile;

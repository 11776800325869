import React, { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import Nav from './Nav';
import { Link } from 'react-scroll';
import NavMobile from './NavMobile';
import Blacklogo from '../assets/img/logo/blacklogo.svg';
import Colourlogo from '../assets/img/logo/colourlogo.svg';

const Header = () => {
  const [header, setHeader] = useState(false);
  const [width, setWidth] = useState(window.innerWidth);
  const [transparentSize, setTransparentSize] = useState(200)
  const [pathMain, setPathMain] = useState(false)
  const breakpoint = 1100;
  const location = useLocation();




  useEffect(() => {
    const handleWindowResize = () => setWidth(window.innerWidth)
    window.addEventListener("resize", handleWindowResize);

    if (width < breakpoint) {
      setTransparentSize(200);
    }
    else {
      setTransparentSize(1000);
    }

  }, [width])


  useEffect(() => {
    window.addEventListener('scroll', () => {
      window.scrollY > transparentSize ? setHeader(true) : setHeader(false);
    });

    if (location.pathname == '/') {
      setPathMain(true);
    }
    else {
      setPathMain(false);
    }
  }, []);

  return (
    <header
      className={`${header ? `bg-white` : `bg-transparent`} fixed w-full px-2 top-0 left-0 sm:px-4 py-2.5 mx-auto rounded-md  shadow-primary z-20 transition-all duration-500 flex items-center justify-between`}
    >
      <div className='container flex flex-wrap items-center mx-auto'>
        {/* logo */}
        <a href='/' className='flex items-center cursor-pointer'>
          <img src={header ? Blacklogo : Colourlogo} alt='' width='130px' />
        </a>
        {/* nav */}
        <div className='hidden justify-between ml-[250px] items-center w-full md:flex md:w-auto md:order-1'>
          <Nav/>
        </div>
      </div>
      <div className='flex items-center'>

        <div className='hidden md:flex gap-x-4 lg:gap-x-9'>
          {pathMain ?
            <button className='btn btn-md lg:px-[20px] mr-10 bg-[#6aa299] text-white font-medium text-sm lg:text-base hover:bg-[#758e8cbf] hover:text-black transition'>
              <Link to='contact' spy={true} smooth={true} offset={50} duration={500}>
                Contact
              </Link>
            </button> :
              <a href='/'><button className='btn btn-md lg:px-[20px] mr-10 bg-[#8ac2d6] text-white font-medium text-sm lg:text-base hover:bg-[#3a98ba] hover:text-black transition'>
            
               
                Contact
             
            
            </button>  </a>}
        </div>

        <NavMobile />
      </div>
    </header>
  );
};


export default Header;

import React, { useEffect, useState } from 'react'
import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';
import { testimonials } from '../data';
import { IoIosArrowUp, IoIosArrowDown } from 'react-icons/io'
const responsive = {
    superLargeDesktop: {
        breakpoint: { max: 3000, min: 1024 },
        items: 2,
    },
    desktop: {
        breakpoint: { max: 500, min: 200 },
        items: 2,
    },
    tablet: {
        breakpoint: { max: 768, min: 640 },
        items: 1,
    },
    mobile: {
        breakpoint: { max: 640, min: 0 },
        items: 1,
    },
};



const Testimonial = () => {
    const [width, setWidth] = useState(window.innerWidth);
    const [isMobile, setIsMobile] = useState(false);
    const [showMore, setShowMore] = useState(false);
    useEffect(() => {
        const handleWindowResize = () => setWidth(window.innerWidth)
        window.addEventListener("resize", handleWindowResize);

        if (width < 700) {
            setIsMobile(true);
        }
        else {
            setIsMobile(false);
        }

    }, [width])


    const customLeftArrow = (
        <div className="absolute arrow-btn left-0 text-center py-3 cursor-pointer bg-black rounded-full">
            <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6 text-black w-full" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="3" d="M10 19l-7-7m0 0l7-7m-7 7h18" />
            </svg>
        </div>
    );
    const CustomDot = ({ onMove, index, onClick, active }) => {
        return (
            <li
                onClick={() => onClick()}
            >
                {active ? <svg xmlns="http://www.w3.org/2000/svg" height="24" viewBox="2 7 20 24" width="24"><path d="M24 24H0V0h24v24z" fill="none" /><circle cx="12" cy="12" r="5" /></svg> :
                    <svg xmlns="http://www.w3.org/2000/svg" height="24" viewBox="0 0 24 24" className='text-black' width="24"><path d="M0 0h24v24H0V0z" fill="none" /><path d="M3 3h18v2H3z" /></svg>}
            </li>
        );
    };

    const customRightArrow = (
        <div className="absolute arrow-btn right-0 text-center py-3 cursor-pointer bg-black rounded-full">
            <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6 text-black w-full" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="3" d="M14 5l7 7m0 0l-7 7m7-7H3" />
            </svg>
        </div>
    );

    return (
        <section id='testimonial' className='section bg-secondary pt-10'>
            <div className='container mx-auto pb-14'>
                <div className='flex flex-col items-center text-center'>
                    <div className='w-9 h-[2px] bg-[#6aa299] mb-3 lg:w-[70px] rounded-full'></div>
                    <h3 className='h3 mb-3 lg:mb-0'>
                        What others say
                    </h3>
                </div>
                <div className="mt-8">

                    {isMobile ? <Carousel infinite showDots ssr slidesToSlide={1} containerClass="carousel-with-custom-dots" partialVisible customDot={<CustomDot />} responsive={responsive} itemClass="pl-2 pt-2">
                        {testimonials.map((post, index) => (
                            <div key={index} className="w-full w-[98%] max-h-[50rem] bg-[#6aa299] shadow-[0_13px_27px_-15px_rgba(5,10,10,3.4)] rounded-lg p-4 mb-8 mt-8 pb-4">
                                <div className="flex flex-col items-center mx-auto mb-4 text-white dark:text-white ">
                                    <div className="flex flex-col items-center justify-center">

                                        <h4 className="text-xl font-semibold text-[#f0f0f0eb] dark:text-white">{post.title}</h4>
                                        <p className="text-sm text-[#f0f0f0eb]">{post.location}</p>
                                    </div>
                                    <p className='md:hidden my-4 font-light'>{showMore ? post.message : `${post.message.substring(0, post.subNo) + `...`}`}</p>
                                    <div className='md:hidden my-4 font-light text-[#abe8ff]' onClick={() => setShowMore(!showMore)}>{showMore ? <p className='inline-flex'> Show Less <span className='pt-1 ml-0.5'><IoIosArrowUp /></span></p> : <p className='inline-flex'>Show More <span className='pt-1.5 ml-0.5'> <IoIosArrowDown /> </span></p>}</div>
                                    <p className='hidden md:flex my-4 font-light'>{post.message}</p>

                                </div>
                            </div>
                        ))}
                    </Carousel>

                        :

                        <Carousel infinite customLeftArrow={customLeftArrow} customRightArrow={customRightArrow} responsive={responsive} itemClass="lg:px-3 px-4 pt-2" className="lg:pl-6">
                            {testimonials.map((post, index) => (
                                <div key={index} className="w-full lg:max-w-[491px] max-w-[35rem] md:max-h-[440px] max-h-[38rem] h-full bg-[#6aa299] shadow-[0_13px_27px_-15px_rgba(5,10,10,3.4)] rounded-lg p-8 mb-8 mt-8 pb-12">
                                    <div className="flex flex-col items-center mx-auto mb-4 text-white lg:mb-8 dark:text-white ">
                                        <div className="flex flex-col items-center justify-center">

                                            <h4 className="text-xl font-semibold text-[#f0f0f0eb] dark:text-white">{post.title}</h4>
                                            <p className="text-sm text-[#f0f0f0eb]">{post.location}</p>
                                        </div>
                                        <p className="my-4 font-light">
                                            {post.message}
                                        </p>
                                    </div>
                                </div>
                            ))}
                        </Carousel>
                    }
               

            </div>
        </div>
        </section >
    );
}

export default Testimonial
import React from 'react';
import { useState, useEffect } from 'react';
import { Parallax } from 'react-parallax';
import Image from '../assets/img/polar.jpg';
// import data
import sanityClient from '../client'
import { BsCalendar2Range, BsChevronRight } from 'react-icons/bs';
import { BiTime } from 'react-icons/bi';

const Pricing = ({ setCourseName }) => {
  const [priceData, setPriceData] = useState([]);
  const [index, setIndex] = useState(0);
  const [isMobile, setIsMobile] = useState(false);

  const imageStyle = {
    opacity: 0.7
  };
  const handleResize = () => {
    if (window.innerWidth < 720) {
      setIsMobile(true)
    } else {
      setIsMobile(false)
    }
  }

  const bookCourse = (title) => {
    setCourseName(title)
  }

  useEffect(() => {
    window.addEventListener("resize", handleResize)
  })

  useEffect(() => {
    sanityClient
      .fetch(`*[_type == "prices"] | order(order asc) {
      title,
      price,
      sessions,
      length,
    }`)
      .then((data) => setPriceData(data))
      .catch(console.error)
  }, [])


  return (

    <Parallax
      bgImage={Image}
      strength={400}
      bgImageStyle={imageStyle}
      renderLayer={percentage => (
        <section className='section-sm lg:section-lg' id='pricing'>
          <div className='container mx-auto'>
            <div className='text-center mb-7 lg:mb-[70px]'>
              <h2
                className='h2 mb-3 lg:mb-[18px] text-white'
                data-aos='fade-up'
                data-aos-delay='400'
              >
              </h2>
            </div>
            {/* pricing cards */}
            <div className='lg:p-24 p-14 pr-1.5 pb-32 flex flex-wrap justify-center gap-14'>
              {priceData
                .map((card, currentIndex) => {
                  // const { title, price, list, people, buttonIcon, buttonText, delay } = card;
                  return (
                    <div
                      onClick={() => setIndex(currentIndex)}
                      className='w-full max-w-[264px] min-h-[267px] h-full bg-white cursor-pointer relative shadow-[0_8px_41px_-7px_rgba(5,10,0,0.4)] rounded-lg'
                      key={currentIndex}
                      data-aos='fade-up'
                      data-aos-offset='300'
                    //  data-aos-delay={delay}
                    >
                      {/* card top */}
                      <div
                        className={`${index === currentIndex
                            ? 'bg-[#6aa299] text-white'
                            : 'bg-white text-heading'
                          } text-center rounded-lg pt-[23px] border-b border-stroke-3 transition`}
                      >
                        <div className='text-[20px] font-medium'>
                          {card.title}
                        </div>
                        <div className='text-[22px] pt-1 font-semibold'>{card.price}</div>
                      </div>
                      <div className='px-[30px] pt-[17px] pb-[7px]'>
                        <ul className='flex flex-col gap-2 mb-10'>

                          <li
                            className='border border-stroke-3 p-4 rounded-md flex items-center gap-2'
                            key={index}
                          >                          <div className='flex justify-center items-center bg-green-100 w-7 h-7 text-2xl text-green-300 rounded-full'>
                              {card.sessions === true ? <BsCalendar2Range /> : <BiTime />}
                            </div>
                            {card.length}
                          </li>

                        </ul>
                        {/* btn */}
                        <a href='#contact'>
                          <div className='absolute bottom-[30px] left-0 right-0 w-full px-[30px]' onClick={() => bookCourse(card.title)} href='#contact'>
                            <button
                              className={`${index === currentIndex
                                  ? ' bg-[#6aa299] text-white'
                                  : ' border-[#6aa299]'
                                } w-full h-[40px] rounded border border-[#6aa299] flex items-center justify-center font-medium transition relative`}
                            >
                              Book Now
                              <div
                                className={`${index === currentIndex ? 'bg-[#468875]' : 'bg-[#6aa299]'
                                  } w-[50px] h-[40px] flex justify-center items-center absolute right-0 rounded text-white`}
                              >
                                <BsChevronRight />
                              </div>
                            </button>
                          </div>
                        </a>
                      </div>
                    </div>
                  );

                })}
              <a href='/corporate'>
                <div
                  onClick={() => setIndex(5)}
                  className='w-full max-w-[264px] min-h-[267px] h-full bg-white cursor-pointer relative shadow-[0_8px_41px_-7px_rgba(5,10,0,0.4)] rounded-lg'
                  data-aos='fade-up'
                  data-aos-offset='300'
                //  data-aos-delay={delay}
                >
                  {/* card top */}
                  <div
                    className={`${index === 5
                        ? 'bg-[#6aa299] text-white'
                        : 'bg-white text-heading'
                      } text-center rounded-lg pt-[23px] border-b border-stroke-3 transition`}
                  >
                    <div className='text-[20px] font-medium'>
                      Corporate Wellness Session
                    </div>
                    <div className='text-[22px] pt-1 font-semibold'>POA</div>
                  </div>
                  <div className='px-[30px] pt-[17px] pb-[7px]'>
                    <ul className='flex flex-col gap-2 mb-10'>

                      <li
                        className='border border-stroke-3 p-4 rounded-md flex items-center gap-2'
                      >                          <div className='flex justify-center items-center bg-green-100 w-7 h-7 text-2xl text-green-300 rounded-full'>
                          <BsCalendar2Range />
                        </div>
                        Custom
                      </li>

                    </ul>
                    {/* btn */}

                    <div className='absolute bottom-[30px] left-0 right-0 w-full px-[30px]'>
                      <button
                        className={`${index === 5
                            ? ' bg-[#6aa299] text-white'
                            : ' border-[#6aa299]'
                          } w-full h-[40px] rounded border border-[#6aa299] flex items-center pl-5 justify-start font-medium transition relative`}
                      >
                        Find Out More
                        <div
                          className={`${index === 5 ? 'bg-[#468875]' : 'bg-[#6aa299]'
                            } w-[50px] h-[40px] flex justify-center items-center absolute right-0 rounded text-white`}
                        >
                          <BsChevronRight />
                        </div>
                      </button>
                    </div>

                  </div>
                </div>
              </a>
            </div>
          </div>
        </section>
      )} />
  );
};

export default Pricing;
import Header from '../Header';
import React from 'react'
import Image from '../../assets/img/background/mountain-2.jpg';
import { Link } from 'react-scroll';


const LandingMobile = () => {
  return (

      <section className='pt-9 bg-homeMobile bg-[length:700px_591px] bg-no-repeat mb-[55px]' id='home'>
        <div className='container mx-auto'>
          < Header /> 
          <div className='flex flex-col items-center text-center pt-32 pb-12'>
            <div className='lg:mt-24 self-auto'>
              <h2
                className="h2 mt-14 text-3xl text-[#e6e6e6]"
                data-aos='fade-up'
                data-aos-delay='100'
                data-aos-duration='2000'
              >
                A Tranquil Mind <br /> <span className='text-[#335e5bdb]'>A Tranquil Life </span>
              </h2>
            
              <div className='w-10 mt-2 h-[2px] bg-[#6aa299] space-x-4 text-center mb-5 rounded-full ml-[46%] mt-[114px]'></div>
              <p
                className="text-[#000000]  mt-10 text-center max-w-[480px] text-opacity-60 font-['Courgette']"
                data-aos='fade-up'
                data-aos-delay='200'
                data-aos-duration='2000'
              >
              Hi, I’m Shona and I am a Solution Focused Hypnotherapist and Psychotherapist based in Farnham, Surrey
              </p>
              <div
                className='space-x-4 text-center pt-12'
                data-aos='fade-up'
                data-aos-delay='300'
                data-aos-duration='1000'
              >
                <button className='btn btn-sm bg-[#6aa299] text-white hover:bg-[#758e8cbf] '>
                  <Link to='contact' spy={true} smooth={true} offset={100} duration={1000}>
                    Contact Me
                  </Link>
                </button>
              </div>
            </div>
        
          </div>
        </div>
      </section>

  )
}

export default LandingMobile
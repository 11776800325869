import React from 'react';

import { Parallax } from 'react-parallax'

import Image from '../assets/img/background/ShonaFord.jpg';

const About = () => {
  return (
    <section className="bg-[#a5c9c3a1] -mt-32 min-h-[260px] pb-[30px] pt-[10px]" id='about'>
      <div className="container max-w-7xl mx-auto px-4">

        <div className="flex flex-wrap items-center mt-12">
          <div className="w-full md:w-5/12 pl-4 mx-auto"
                        data-aos='fade-right'
                        data-aos-delay='700'
          >
            <h2 className='h2 md:text-4xl text-2xl font-bold mb-3 pt-6 md:pt-0 md:pb-3'>A bit about me</h2>
            <p className='pt-6 md:pt-0'>
            Situated in Farnham, I live with my husband and lovely ginger cat, Max! I am also a mother to three wonderful children, all in their 20s.
            I chose to work in this form of therapy after seeing the positive, life changing results it had on my daughter, who’s everyday life was affected by her anxiety. This inspired me to help others who may be struggling. 
            I studied at the highly regarded Clifton Practice of Hypnotherapy where I gained a Diploma in Solution Focused Hypnotherapy (DSFH). 
            </p>
            <a
              href="#contact"
              className="font-medium text-light-blue-500 mt-2 inline-block italic md:pt-3"
            >
              Contact me
            </a>
          </div>
          <div className="w-full md:w-4/12 pt-7 pb-7 mx-auto flex justify-center lg:mt-0"
           data-aos='fade-left'
           data-aos-delay='200'
          >
            <img src={Image} alt='woman' className='w-[250px] lg:w-[320px] bg-white h-auto shadow-[0_8px_29px_-7px_rgba(5,10,0,0.4)] rounded-lg align-middle border-none'/>
          </div>

        </div>
      </div>
    </section>
  );
};

export default About;
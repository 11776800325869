import React from 'react';

import Image from '../assets/img/courses/drowning.jpg'
import Image2 from '../assets/img/courses/anxiety.jpg'
import Box from '../assets/img/background/Box1.png'
import Box2 from '../assets/img/background/Box2.png'

// import icons
import { BsArrowRight } from 'react-icons/bs';

const Hypnotherapy = () => {


  return (
    <section className='mb-[70px] xl:mb-[150px] mt-10' id='hypnotherapy'>
      <div className='container mx-auto'>
        <div className='flex flex-col items-center text-center'>
          <div className='w-9 h-[2px] bg-[#6aa299] mb-3 lg:w-[70px] rounded-full'></div>
          <h3 className='h3 mb-6 lg:mb-[79px]'>
            What is Hypnotherapy?
          </h3>
        </div>
        <div className='grid grid-cols-1 lg:gap-[138px] xl:grid-cols-2'>
        <div
                className='w-full max-w-[530px] h-[358px] relative flex flex-col items-center justify-center xl:flex-row xl:justify-start mx-auto'
                data-aos='zoom-in'
                data-aos-offset='100'
                data-aos-delay='200'
              >
                {/* bg image */}
                <div className='xl:flex absolute top-0 right-0 -z-10'>
                  <img src={Box2} className='md:max-w-[563px] max-w-[464px]' />
                </div>

              
             
                <div className='max-w-[620px] pr-2.5 md:pl-2 pl-5 pt-4 md:pt-0 pb-12 md:pb-0'>
                  <h3 className='h3 mb-4 text-xl'>Solution Focused Hypnotherapy</h3>
                  <p className='font-light italic md:text-lg text-md mb-4'>
                  It is a forward focused brief therapy. We work together to find solutions and a preferred future, without re-living past memories or events.
This practise is a modern mix of hypnosis and psychotherapy and is an effective way of reducing anxiety and stress, minimising specific phobias and increasing confidence.

</p>

                </div>
              </div>
          <div
            className='max-w-[530px] md:h-[358px] h-[317px] relative flex flex-col items-center justify-center xl:flex-row xl:justify-start mx-auto'
            data-aos='zoom-in'
            data-aos-offset='100'
            data-aos-delay='200'
          >
            {/* bg image */}
            <div className='xl:flex absolute top-0 right-0 -z-10'>
              <img src={Box} className='md:max-w-[563px] max-w-[420px]' />
            </div>

            {/* icon image */}
            {/* <div
                  className='max-w-[120px] xl:mr-7 xl:max-w-[232px]'
                  data-aos='zoom-in-right'
                  data-aos-delay='300'
                >
                  <img src={Image} />
                </div> */}
            {/* text */}

            <div className='max-w-[620px] pl-5 md:pl-2 pb-8 pr-2'>
              <h3 className='h3 mb-4 md:pl-0 text-xl'>Hypnosis</h3>
              <p className='font-light md:text-lg text-base italic mb-4'>It is natural and safe state, similar to daydreaming.  Hypnosis in a theraputic setting  will give you a feeling of deep relaxation, allowing the mind to focus on thoughts for a positive change.
                Hypnosis alone can reduce anxiety and improve sleep.

              </p>
            </div>
          </div>

        </div>
      </div>
    </section>
  );
};

export default Hypnotherapy;
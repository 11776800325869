import React, { useState, useEffect, useRef } from 'react';

// import email js
import emailjs from '@emailjs/browser';
import { pricing } from '../data';

const Contact = ({ getCourseName }) => {
  const form = useRef();
  const [emailMesssage, setEmailMessage] = useState('');
  const [emailTextColor, setEmailTextColor] = useState('');
  const [courseName, setCourseName] = useState('')
  useEffect(() => {
    const timer = setTimeout(() => {
      setEmailMessage('');
    }, 3000);

    // clear timer
    return () => clearTimeout(timer);
  }, [emailMesssage]);

  useEffect(() => {
    setCourseName(getCourseName)
  }, [getCourseName])
  
  const sendEmail = (e) => {
    e.preventDefault();
    
    emailjs
      .sendForm(
        'service_sh3h6ua',
        'template_ulopm2s',
        form.current,
        '7vcBZsDK-JpfKvP-R'
      )
      .then(
        (res) => {
          setEmailMessage('Your email was sent :)');
          setEmailTextColor('green');
        },
        (err) => {
          setEmailMessage('Your email was not sent :(');
          setEmailTextColor('red');
        }
      );
    e.target.reset();
  };

  return (
    <section className='section-sm lg:pt-[250px]' id='contact'>
      <div className='container mx-auto' id='contact'>
        <div
          className='flex flex-col lg:flex-row lg:gap-x-[74px] bg-contact bg-no-repeat bg-cover min-h-[600px]'
        >
          <div
            className='flex-1 flex flex-col justify-center pl-8'
          >
            <p className='h2 mb-3 lg:mb-7 text-[#636363]'>
              Get In Touch.
            </p>
          
          </div>
          <form
            onSubmit={(e) => sendEmail(e)}
            ref={form}
            className='flex-1 bg-white shadow-primary rounded-[20px] p-5 lg:p-10 flex flex-col gap-y-5 max-h-[600px] lg:-mt-20'
          >
            <input
              className='form-control'
              placeholder='First name'
              type='text'
              name='user_firstname'
              required
            />
            <input
              className='form-control'
              placeholder='Last name'
              type='text'
              name='user_lastname'
              required
            />
            <input
              className='form-control'
              placeholder='Email address'
              type='email'
              name='user_email'
              required
            />
          
          <select id="courses" name='user_course' className="form-control bg-white text-grey rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full dark:placeholder-gray-400 dark:focus:ring-blue-500 dark:focus:border-blue-500">
             <option selected>Select a session</option>
             {pricing.map((item, index) => {
              const { title } = item;
              if (getCourseName === title) {
                return (
                  <option selected key={index} value={title}>{title}</option>
                 )
              }
              else {
                return (
                  <option key={index} value={title}>{title}</option>
                 )
              }
            
                
            })}
          
          </select>
            <textarea
              className='form-control py-5 h-[165px] resize-none'
              placeholder='Message'
              name='user_message'
            ></textarea>
            <button className='btn btn-lg bg-[#6aa299] text-white self-start' type='submit'>
              Send message
            </button>
            <p style={{ color: emailTextColor }}>{emailMesssage}</p>
          </form>
        </div>
      </div>
    </section>
  );
};

export default Contact;
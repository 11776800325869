import React from 'react';
import { useState, useEffect } from 'react';
// import data
import { BsCalendar2Range, BsChevronRight } from 'react-icons/bs';
import { BiTime } from 'react-icons/bi';

import sanityClient from '../../client'

const PricingMobile = ({ setCourseName }) => {
  const [priceData, setPriceData] = useState([]);
  const [index, setIndex] = useState(0);
  const [isMobile, setIsMobile] = useState(false);


  const handleResize = () => {
    if (window.innerWidth < 720) {
        setIsMobile(true)
    } else {
        setIsMobile(false)
    }
  }
  const bookCourse = (title) => {
    setCourseName(title)
  }

  useEffect(() => {
    window.addEventListener("resize", handleResize)
  })

  useEffect(() => {
    sanityClient
    .fetch(`*[_type == "prices"] | order(order asc) {
      title,
      price,
      sessions,
      length,
    }`)
    .then((data) => setPriceData(data))
    .catch(console.error)
  }, [])


 
  return (
  
    <section className='mb-[10px] bg-[#F5F6F9]' id='pricing'>
      <div className='mx-auto'>
       
        <div className='text-center mb-7 h-full bg-polar bg-cover opacity-70'> 
          <h2
            className='h2 text-white p-[26%]'
            data-aos='fade-up'
            data-aos-duration='1000'
            data-aos-offset='200'
          >
            Pricing
          </h2>
        </div>
        {/* pricing cards */}
        <div className='px-4 grid grid-cols-2 gap-6'>
        {priceData
          .map((card, currentIndex) => {
            return (
                // Changes here
              <div
                onClick={() => setIndex(currentIndex)}
                className='w-full max-w-[194px] min-h-[179px] h-full bg-white cursor-pointer relative shadow-[0_8px_41px_-7px_rgba(5,10,0,0.4)] rounded-lg'
                key={currentIndex}
              >
                {/* card top */}
                <div
                  className={`${
                    index === currentIndex
                      ? 'bg-[#6aa299] text-white'
                      : 'bg-white text-heading'
                  } text-center rounded-lg pt-2 border-b border-stroke-3 transition`}
                >
                  <div className='text-[15px] font-medium'>
                    {card.title}
                  </div>
                  <div className='text-[15px] pt-1 font-semibold'>{card.price}</div>
                </div>

                {/* Changes here */}
                <div className='px-[9px] pt-2 pb-2'>

                  <ul className='flex flex-col gap-2'>

                        <li
                          className='border border-stroke-3 p-4 rounded-md flex items-center text-[12.5px] gap-2'
                 
                        >               {/* Changes here */}
                                      <div className='flex justify-center items-center bg-green-100 w-5 h-5 text-xl text-green-300 rounded-full'>
                                      {card.sessions === true ? <BsCalendar2Range /> : <BiTime />}
                                      
                      </div>
                      {card.length}
                        </li>
                  </ul>
                  {/* btn */}
                     {/* Changes here */}
                  <a href='#contact'>
                    <div className='absolute bottom-[15px] left-0 right-0 w-full px-[8px]' onClick={() => bookCourse(card.title)} >
                      <button
                        className={`${
                          index === currentIndex
                            ? ' bg-[#6aa299] text-white'
                            : ' border-[#6aa299] bg-white'
                        } w-full h-[30px] rounded border pr-2 border-[#6aa299] flex items-center text-sm justify-center font-medium transition relative`}
                      >
                        Book Now
                        <div
                          className={`${
                            index === currentIndex ? 'bg-[#4a7271]' : 'bg-[#6aa299]'
                          } w-[30px] h-[30px] flex justify-center items-center absolute right-0 rounded text-white`}
                        >
                           <BsChevronRight />
                        </div>
                      </button>
                    </div>
                  </a>
                </div>
              </div>
            );
          })}
         
        </div>
        <div className='px-4 pb-10 mt-6 flex mx-auto justify-center'>
        <div
                onClick={() => setIndex(5)}
                className='w-full max-w-[194px] min-h-[179px] h-full bg-white cursor-pointer relative shadow-[0_8px_41px_-7px_rgba(5,10,0,0.4)] rounded-lg'
              >
                {/* card top */}
                <div
                  className={`${
                    index === 5
                      ? 'bg-[#6aa299] text-white'
                      : 'bg-white text-heading'
                  } text-center rounded-lg pt-2 border-b border-stroke-3 transition`}
                >
                  <div className='text-[15px] font-medium'>
                  Corporate Wellness Session
                  </div>
                  <div className='text-[15px] pt-1 font-semibold'>POA</div>
                </div>

                {/* Changes here */}
                <div className='px-[9px] pt-2 pb-2'>

                  <ul className='flex flex-col gap-2'>

                        <li
                          className='border border-stroke-3 p-4 rounded-md flex items-center text-[12.5px] gap-2'
                 
                        >               {/* Changes here */}
                                      <div className='flex justify-center items-center bg-green-100 w-5 h-5 text-xl text-green-300 rounded-full'>
                                      <BsCalendar2Range /> 
                                      
                      </div>
                     Custom
                        </li>
                  </ul>
                  {/* btn */}
                     {/* Changes here */}
                  <a href='/corporate'>
                    <div className='absolute bottom-[15px] left-0 right-0 w-full px-[8px]' >
                      <button
                        className={`${
                          index === 5
                            ? ' bg-[#6aa299] text-white'
                            : ' border-[#6aa299] bg-white'
                        } w-full h-[30px] rounded border pr-2 border-[#6aa299] flex items-center text-sm justify-center font-medium transition relative`}
                      >
                        Find Out More
                        <div
                          className={`${
                            index === 5 ? 'bg-[#4a7271]' : 'bg-[#6aa299]'
                          } w-[30px] h-[30px] flex justify-center items-center absolute right-0 rounded text-white`}
                        >
                           <BsChevronRight />
                        </div>
                      </button>
                    </div>
                  </a>
                </div>
              </div>
        </div>
      </div>
    </section>
  )
};

export default PricingMobile;
import React, {useState} from 'react';

// import logo
import Blacklogo from '../assets/img/logo/blacklogo.svg';
import AfSfh from '../assets/img/logo/afsfh.png';
import nch from '../assets/img/logo/nch.png';
import cnhc from '../assets/img/logo/cnhc.jpg';

// import icons
import { FaInstagram, FaFacebook } from 'react-icons/fa';
import { MdEmail } from 'react-icons/md';


const Footer = () => {
  
  const [currentYear] = useState(() =>  new Date().getFullYear());
  return (
    <footer className='pb-[70px]' data-aos='fade-up' data-aos-offset='0'>
      <div className='container items-center w-full mx-auto'>
        <div className='flex flex-col justify-between items-center lg:flex-row gap-y-5'>
          {/* logo */}
          <div className='flex'>
            <a href='#' className='pt-3 md:pt-0'>
              <img src={Blacklogo} width='158px' height='158px' alt='' />
            </a>
            <a href='https://www.afsfh.com/' >
              <img src={AfSfh} className='ml-3 mt-[29px]' width='158px' height='158px' alt='' />
            </a>
            <a href='https://www.hypnotherapists.org.uk/'>
              <img src={nch} className='ml-3 mt-[29px]' width='100px' height='158px' alt='' />
            </a>
            <a href='https://www.cnhc.org.uk/'>
              <img src={cnhc} className='ml-3 mt-[29px]' width='100px' height='158px' alt='' />
            </a>
          </div>
          {/* copyright text */}
          <div>
            <p className='pt-8'>&copy; {currentYear}. All rights reserved.</p>
            <a href='/Privacy' className='text-center'>
              <p className='pt-1'>Privacy</p>
            </a>
            <a href='https://www.linkedin.com/in/tiarnan-power-71b36362/' className='text-center'>
              <p className='text-[12px] text-center pt-4'>Built by Tiarnan Power</p>
            </a>
          </div>
          {/* social icons */}
          <div className='flex gap-x-4 text-[#6aa299] text-lg'>
            <div className='w-[50px] h-[50px] bg-white rounded-full flex justify-center items-center shadow-primary cursor-pointer hover:text-paragraph transition'>
              <a href='mailto:shonahypnotherapy@gmail.com'>
                <MdEmail />
              </a>
            </div>
            <div className='w-[50px] h-[50px] bg-white rounded-full flex justify-center items-center shadow-primary cursor-pointer hover:text-paragraph transition'>
              <a href="https://www.instagram.com/shonahypnotherapy/">
              <FaInstagram />
              </a>
            </div>
            <div className='w-[50px] h-[50px] bg-white rounded-full flex justify-center items-center shadow-primary cursor-pointer hover:text-paragraph transition'>
              <a href="https://www.facebook.com/profile.php?id=100086099062425">
                <FaFacebook />
              </a>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
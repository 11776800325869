import {createClient} from "@sanity/client"
import imageUrlBuilder from '@sanity/image-url'

export default createClient ({
    projectId: "so5inhyj",
    dataset: "production",
    useCdn: false,
    apiVersion: "2021-08-05",
})

export const config = {
  projectId: "so5inhyj",
  dataset: "production",
  useCdn: false,
  apiVersion: "2021-08-05",
};
  
  const builder = imageUrlBuilder(config)

 export const urlFor = (source) => builder.image(source)
  
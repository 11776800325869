import React, {useEffect, useState} from 'react'
import { courses } from '../data';
// import icons
import { BsChevronRight } from 'react-icons/bs';

const Courses = () => {
  const [showMore, setShowMore] = useState(false);

  
  return (

    <section
      className='bg-[#6aa299] min-h-[362px] pt-9' id='courses'
    >
      <div className='md:container mx-auto'>
        {/* text */}
        <div className='text-center lg:mb-14'>
          <h3
            className='h3 mb-3 lg:mb-[18px] lg:text-3xl text-3xl mt-4 text-[#f0f0f0eb]'
            data-aos='fade-down'
            data-aos-delay='200'
          >
            What I can help with
          </h3>
          {/* <p
            className='max-w-[480px] mx-auto text-white'
            data-aos='fade-down'
            data-aos-delay='300'
          >
           Course overview description?????
          </p> */}
        </div>
        {/* course list */}

        <div className="p-3 md:p-0 pb-32 grid grid-cols-2 md:grid-cols-3 lg:grid-cols-4 xl:grid-cols-4 lg:gap-x-7 gap-5 gap-y-11 ">
          {courses.map((item, index) => {
            const { image, title, desc, link, delay, subNo } = item;
            return (
              <div className="overflow-hidden shadow-lg max-w-[468px] h-[94%] bg-white pb-[26px] lg:px-4 lg:pb-[30px] hover:shadow-primary rounded-[14px] transition"
              data-aos='fade-up'
              data-aos-delay={delay}
              key={index}
              >
                <div className='md:mx-7 pb-6 px-[18px] pt-3 max-w-[230px]'>
                  <img src={image} alt='' />
                </div>
                <div className='pb-[13px]'>
                  <h4 className='text-base lg:text-xl px-[18px] font-semibold mb-2 lg:mb-4'>
                    {title}
                  </h4>
                  <p className='md:hidden px-2 text-light text-sm'>{showMore? desc : `${desc.substring(0, subNo) + `...`}`}</p>
                  <p className='md:hidden px-2 pt-1 text-light text-sm text-[#506ab1]' onClick={() => setShowMore(!showMore)}>{showMore ? "Show Less" : "Show More"}</p>
                  <p className='hidden md:flex px-2 text-light text-sm'>{desc}</p>
                </div>
             
                </div>

            );
          })}
        </div>
      </div>
    </section>




  )
}

export default Courses
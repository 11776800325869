import React, {useEffect, useState} from 'react';

import sanityClient from '../client'
import { urlFor } from '../client';
import Header from '../components/Header';
import { Link } from 'react-scroll';

const CorporateWellness = () => {
    const [corporateData, setCorporateData] = useState([]);

    useEffect(() => {
        sanityClient
          .fetch(`*[_type == "corporate"]`)
          .then((data) => setCorporateData(data))
          .catch(console.error)
      }, [])

    return (

        <div className='container mx-auto '>
            <Header />
            <div className="text-gray-600 body-font" >
                <div className="container mx-auto flex px-5 lg:py-24 pt-32 pb-12 md:flex-row flex-col items-center">
                    <div className="lg:max-w-lg lg:w-full w-5/6 mb-10 md:mb-0">
                        <img className="object-cover object-center rounded" alt="hero" src={urlFor(corporateData[0].image1[0]).url()} data-aos='fade-up' 
                data-aos-duration='2000' />
                    </div>
                    <div className="lg:flex-grow md:w-1/2 lg:pl-24 flex flex-col md:items-start md:text-left items-center text-center">
                        <h1 className="title-font sm:text-4xl text-3xl mb-4 font-medium text-[#a3fef8]" data-aos='fade-left' >{corporateData[0].title1}
                        </h1>
                        <p className="mb-8 leading-relaxed text-white" data-aos="zoom-in">{corporateData[0].description1}</p>
                        <div className="flex justify-center" data-aos="zoom-in">
                            <button className='btn btn-sm lg:btn-lg bg-[#a7dcd2] text-black transition hover:text-white lg:mr-2 hover:bg-[#758e8cbf] '>
                                <Link to='contact' spy={true} smooth={true} offset={50} duration={1000}>
                                    Contact Me
                                </Link>
                            </button>
                        </div>
                    </div>
                </div>
            </div>
            <div className="text-gray-600 body-font">
                <div className="container mx-auto flex px-5 py-24 md:flex-row flex-col items-center">
                    <div className="lg:flex-grow md:w-1/2 lg:pr-24 flex flex-col md:items-start md:text-left mb-16 md:mb-0 items-center text-center">
                        <h1 className="title-font sm:text-4xl text-3xl mb-4 font-medium text-[#a3fef8]" data-aos='fade-right' >Before they sold out
                        </h1>
                        <p className="mb-8 leading-relaxed text-white" data-aos='fade-up' >Copper mug try-hard pitchfork pour-over freegan heirloom neutra air plant cold-pressed tacos poke beard tote bag. Heirloom echo park mlkshk tote bag selvage hot chicken authentic tumeric truffaut hexagon try-hard chambray.</p>
                        <div className="flex justify-center">
                            <button className='btn btn-sm lg:btn-lg bg-[#a7dcd2] text-black transition hover:text-white lg:mr-2 hover:bg-[#758e8cbf] data-aos="zoom-in"'>
                                <Link to='contact' spy={true} smooth={true} offset={50} duration={1000}>
                                    Contact Me
                                </Link>
                            </button>
                        </div>
                    </div>
                    <div className="lg:max-w-lg lg:w-full w-5/6">
                        <img className="object-cover object-center rounded" alt="hero" src="https://dummyimage.com/720x600" data-aos='fade-right' />
                    </div>
                </div>
            </div>

        </div>


    )
}

export default CorporateWellness